<template>
  <footer class="footer--small" v-if="!loading">
    <div class="site-width">
      <div class="footer__copyrights" v-html="data.footerCopyright"></div>
    </div>
  </footer>
</template>

<script>
import {useStore} from "vuex";
import {computed, watchEffect, ref} from 'vue'

export default {
  name: 'footer-small-line',
  setup() {
    const store = useStore();
    const data = computed(() => store.getters.data);
    const loading = ref(false);

    watchEffect(() => loading.value = !data.value)


    return {data, loading};
  }
};
</script>
