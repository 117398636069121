<template>
  <template v-if="!loading">
    <header-line></header-line>
    <div class="content content--center">
      <section class="search">
        <div class="search__title">
          <strong>{{ t('message.voucher_search') }}</strong> {{ t('message.specify_voucher_info') }}
        </div>
        <div class="search__form">
          <form action="#" class="form" v-on:submit.prevent="search">
            <div class="form__row">
              <input class="form__input" type="text" name="search" autocomplete="off"
                     v-model="searchInput"
                     v-bind:placeholder="t('label.voucher_code')"
                     v-on:keyup="notFound = false"
              >
            </div>
            <div class="form__row--error" v-if="notFound">
              {{ t('message.not_found_voucher') }}
            </div>
            <div class="form__row form__row--btns">
              <input class="btns btn-blue" type="submit" name="submit"
                     v-bind:value="t('button.find_voucher')"
              >
            </div>
          </form>
        </div>
      </section>
    </div>
    <footer-small-line></footer-small-line>
  </template>
  <page-loader v-else></page-loader>
</template>

<script>
import {useI18n} from 'vue-i18n'
import {useStore} from 'vuex'
import {ref, watchEffect, computed} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import HeaderLine from "@/components/HeaderLine";
import {ACTION_TYPES} from "@/constants";
import PageLoader from "@/components/PageLoader";
import useAdmin from "@/helpers/useAdmin";
import FooterSmallLine from "@/components/FooterSmallLine";

export default {
  name: 'admin-search',
  components: {FooterSmallLine, PageLoader, HeaderLine},
  setup() {
    const store = useStore();
    const {admin, redirectIfNotLoggedIn} = useAdmin();

    const loading = ref(false);
    const notFound = ref(false);
    const searchInput = ref('');

    const router = useRouter();
    const route = useRoute();
    watchEffect(() => {
      redirectIfNotLoggedIn(router);
      loading.value = !admin.value;
    })

    const data = computed(() => store.getters.data);

    const search = () => {
      if (searchInput.value.length < 2) {
        return;
      }
      loading.value = true;
      store.dispatch(ACTION_TYPES.findBookedVoucher, searchInput.value)
          .then(() => {
                const bookedVoucher = store.getters.bookedVoucher;
                if (bookedVoucher) {
                  router.push({name: 'admin_voucher', params: {...route.params, code: searchInput.value}});
                } else {
                  notFound.value = true;
                }
                loading.value = false;
              }
          )
          .catch(() => {
                loading.value = false;
                notFound.value = true;
              }
          );
    }

    const i18n = useI18n();

    return {t: i18n.t, loading, searchInput, search, data, notFound};
  }
}
</script>

